export function isValidCurrency(keyCode: number, value: string): boolean {
  // only allow number and one dot
  if ((keyCode === 45 || keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') !== -1)) { // 46 is dot
    if (value.length > 0 && keyCode !== 45) {
      return false;
    }
  }

  // restrict to 2 decimal places
  if (value !== null && value.indexOf('.') > -1 && (value.split('.')[1].length > 1)) {
    return false;
  }

  return true;
}

export default function onlyAllowCurrency($event: KeyboardEvent, value: string) {
  const keyCode = ($event.keyCode ? $event.keyCode : $event.which);

  if (!isValidCurrency(keyCode, value || '')) {
    $event.preventDefault();
  }
}
